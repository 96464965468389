import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/react";

import Layout from "../components/layout";
import ShareSec from "../components/shareSec";
import linkArrow from "../images/linkArrow.svg";

import "@fontsource/arvo";

const News = ({ data }) => {
  return (
    <Layout>
      <main css={mainContainer}>
        <span css={date}>{data.wpPost.date}</span>
        <h1 css={title}>{data.wpPost.title}</h1>
        <ShareSec data={data} />
        <div
          css={content}
          dangerouslySetInnerHTML={{ __html: data.wpPost.content }}
        />
        <Link to="/all-news" css={backLink}>
          一覧へ戻る
        </Link>
      </main>
    </Layout>
  );
};

export default News;

const mainContainer = css`
  max-width: 900px;
  margin: 125px auto 0;

  @media (max-width: 767px) {
    max-width: 100%;
    padding: 0 15px;
    margin: 45px auto 0;
  }
`;

const date = css`
  font-family: "Arvo";
  margin-bottom: 15px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const title = css`
  font-size: 30px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const content = css`
  margin: 86px 0 100px;

  @media (max-width: 767px) {
    margin: 50px 0 60px;
  }

  // margin-bottom for each block
  p,
  figure,
  h2,
  h3,
  h4,
  ul,
  ol,
  .wp-block-group.news-box {
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  // if you don't want margin-bottom
  .clear-margin-bottom {
    margin-bottom: 0;
  }

  // nomal text
  p {
    font-weight: 400;
    line-height: 1.875;

    @media (max-width: 767px) {
      font-size: 14px;
    }

    // text link
    a {
      color: #003c90;
      text-decoration: underline;
      font-weight: 400;
    }
  }

  // image
  .wp-block-image {
    width: 100%;

    div {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  // font size
  h2 {
    font-size: 26px;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  h3 {
    font-size: 22px;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  h4 {
    font-size: 19px;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  // block with border
  .wp-block-group.news-box {
    border: 1px solid rgba(157, 167, 181, 0.5);
    padding: 30px;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }

  // list style
  li {
    margin-left: 30px;
    padding-left: 10px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  //table
  .wp-block-table {
    table {
      width: 100%;
      border-collapse: collapse;
    }

    th,
    td {
      padding: 20px;
    }

    tr {
      border-top: 1px solid rgba(157, 167, 181, 0.5);
      border-bottom: 1px solid rgba(157, 167, 181, 0.5);
    }

    th {
      border-right: 1px solid rgba(157, 167, 181, 0.5);
      font-weight: bold;
      background: #f0f2f4;
      text-align: left;
    }
  }

  // red color text
  .has-vivid-red-color {
    color: #ff0000;
  }

  // block quote
  .wp-block-quote {
    border-left: 3px solid #dddddd;
    padding-left: 24px;
  }
`;

const backLink = css`
  display: inline-block;
  width: 100%;
  text-align: center;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: 7px;
    background-image: url(${linkArrow});
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(-1, 1);
    margin-right: 25px;
  }
`;

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      content
      date(formatString: "YYYY.MM.DD")
      title
      link
    }
  }
`;
