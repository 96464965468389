import React from "react";
import { useLocation } from "@reach/router";
import { css } from "@emotion/react";

import shareLink from "../images/shareLink.svg";
import shareFB from "../images/shareFB.svg";
import shareTwitter from "../images/shareTwitter.svg";

import "@fontsource/arvo";

const ShareSec = ({ data }) => {
  const location = useLocation();

  // copy post URL to clipboard
  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(location.href);
  };

  return (
    <section css={shareContainer}>
      <div css={text}>SHARE :</div>
      <div css={btns}>
        <button onClick={copyToClipboard}>
          <img src={shareLink} alt="share link" />
        </button>
        <a
          href={`http://www.facebook.com/share.php?u=mashingup.org${data.wpPost.link}`}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={shareFB} alt="share facebook" />
        </a>
        <a
          href={`https://twitter.com/share?url=mashingup.org${data.wpPost.link}&text=${data.wpPost.title}`}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={shareTwitter} alt="share twitter" />
        </a>
      </div>
    </section>
  );
};

export default ShareSec;

const shareContainer = css`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const text = css`
  font-size: 10px;
  font-family: "Arvo";
  color: #92969e;
  letter-spacing: 0px;
`;

const btns = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;

  a {
    display: inline-block;
  }
`;
